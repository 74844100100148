// getHomeLiveStreamIds.js
'use strict';
import { createSelector } from '../resource/createCachedSelector.js';

import getListData from '../selector/getListData.js';
import {
  SHOW_LIVE_STREAM_AD_THRESHOLD,
  livestreamAdType as livestreamAdTypeConstant,
} from '../resource/liveStreamConstants.js';
import { getIsChinaPartner } from '../resource/partner.js';
import {
  FEATURE_LIVESTREAM_AD,
  FEATURE_LIVESTREAM_AD_2,
} from '../RemoteConfigKeys.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

const defaultList = [];
const isChinaPartner = getIsChinaPartner();

/**
 * Select home live stream ids
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {array} selectPath - select path.
 * @return {array} The selected home live stream ids.
 */
const getHomeLiveStreamIds = createSelector(
  (state, selectPath) => getListData(state, selectPath, 'itemIds'),
  state => getRemoteConfigData(state, FEATURE_LIVESTREAM_AD),
  state => getRemoteConfigData(state, FEATURE_LIVESTREAM_AD_2),
  (streamIds = defaultList, swaggerAdUrl = null, advertiserAdUrl = null) => {
    if (isChinaPartner) return streamIds;

    let adArray = [];
    if (swaggerAdUrl) adArray.push(livestreamAdTypeConstant.SWAGGER);
    if (advertiserAdUrl) adArray.push(livestreamAdTypeConstant.ADVERTISER);

    if (streamIds.length >= SHOW_LIVE_STREAM_AD_THRESHOLD) {
      const ids = [...streamIds];
      ids.splice(SHOW_LIVE_STREAM_AD_THRESHOLD, 0, ...adArray);
      return ids;
    }

    return streamIds;
  }
);

export default getHomeLiveStreamIds;
