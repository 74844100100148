// HomeLiveStreamFeed.js
'use strict';
import { connect } from 'react-redux';

import getMeData from '../selector/getMeData.js';
import getListData from '../selector/getListData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getHomeLiveStreamIds from '../selector/getHomeLiveStreamIds.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';
import { getSelectedQueryOptionObject } from '../selector/getFeedQueryObject.js';
import getFeedRenewData from '../selector/getFeedRenewData.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import getOperationData from '../selector/getOperationData.js';

import fetchFeeds from '../action/fetchFeeds.js';
import subscribeFeedChannel from '../action/subscribeFeedChannel.js';
import unsubscribeFeedChannel from '../action/unsubscribeFeedChannel.js';
import setSelectedFeedQueryOption from '../action/setSelectedFeedQueryOption.js';

import HomeLiveStreamFeed from '../component/HomeLiveStreamFeed.jsx';

import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_USER,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';
import getIsPermissionMatched from '../selector/getIsPermissionMatched.js';
import { QueryType } from '../resource/feedConstants.js';

import {
  SEARCH_SWAGGER_ONLINE_FEED,
  LIVESTREAM_FEED,
  LIVESTREAM_FEED_SORTINGS,
  JWT_SCOPE_CURATOR,
} from '../RemoteConfigKeys.js';

const mapStateToProps = (state, { category }) => {
  const isAuthed = !!getMeData(state, 'id');
  const isRemoteConfigMerged = isAuthed
    ? getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_USER)
    : getIsRemoteConfigMerged(state, CONFIG_PRIORITY_PRESENCE_CLIENT) ||
      getIsRemoteConfigMerged(state, CONFIG_PRIORITY_CONFIGURE);
  const swaggerOnlineFeed = getRemoteConfigData(
    state,
    SEARCH_SWAGGER_ONLINE_FEED
  );
  const { feedNameWithQuery, intersectedFeedName } =
    getSelectedQueryOptionObject(
      state,
      category,
      getSelectedFeedQueryOptions(state, LIVESTREAM_FEED, undefined),
      getRemoteConfigData(state, LIVESTREAM_FEED_SORTINGS)?.[0]
    );
  const liveStreamSelectPath = ['home', 'users', feedNameWithQuery];

  const userIds = getHomeLiveStreamIds(state, liveStreamSelectPath, 'itemIds');
  const nextPage = getListData(state, liveStreamSelectPath, 'nextPage');
  const isNextPageFetching = !!getNetworkingData(
    state,
    [...liveStreamSelectPath, nextPage],
    'isFetching'
  );
  const isFirstPageFetching = !!getNetworkingData(
    state,
    [...liveStreamSelectPath, 1],
    'isFetching'
  );
  const isFirstPageFetched = !!getNetworkingData(
    state,
    [...liveStreamSelectPath, 1],
    'isFetched'
  );

  const onlineUserSelectPath = ['home', 'users', swaggerOnlineFeed];
  const onlineUserIds = getListData(state, onlineUserSelectPath, 'itemIds');
  const onlineUserNextPage = getListData(
    state,
    onlineUserSelectPath,
    'nextPage'
  );
  const isOnlineUserNextPageFetching = !!getNetworkingData(
    state,
    [...onlineUserSelectPath, onlineUserNextPage],
    'isFetching'
  );
  const isOnlineUserFirstPageFetching = !!getNetworkingData(
    state,
    [...onlineUserSelectPath, 1],
    'isFetching'
  );
  const isCurator = getIsPermissionMatched(state, JWT_SCOPE_CURATOR);

  return {
    isAuthed,
    isRemoteConfigMerged,
    category,
    feedNameWithQuery,
    intersectedFeedName,
    swaggerOnlineFeed,
    renewFeedId: getFeedRenewData(state, intersectedFeedName, 'id'),
    latestFeedRenewUnix: getFeedRenewData(
      state,
      intersectedFeedName,
      'timestamp'
    ),
    userIds,
    selectedFilterOptions: getSelectedFeedQueryOptions(
      state,
      LIVESTREAM_FEED,
      QueryType.FILTER
    ),
    nextPage,
    isNextPageFetching,
    isFirstPageFetching,
    isFirstPageFetched,
    onlineUserIds,
    onlineUserNextPage,
    isOnlineUserNextPageFetching,
    isOnlineUserFirstPageFetching,
    feedRenewObject: getFeedRenewData(state, intersectedFeedName),
    shouldUseCuratorMode: isCurator,
    isBrowserTabVisible: getOperationData(
      state,
      ['visibility'],
      'isBrowserTabVisible'
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: payload => dispatch(fetchFeeds(payload)),
    subscribeFeedChannel: ({ feedName }) =>
      dispatch(subscribeFeedChannel({ feedName })),
    unsubscribeFeedChannel: ({ feedName }) =>
      dispatch(unsubscribeFeedChannel({ feedName })),
    setSelectedFeedQueryOption: payload =>
      dispatch(setSelectedFeedQueryOption(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLiveStreamFeed);
